import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "../../common/ListingPage.css";
import axios from "axios";
import conf from "../../config/config";
import { AuthContext } from "../../context/AuthContext";

const TestReportList = ({ testreports }) => {
  const { user, logout } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "createddate",
    direction: "desc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const testreportsPerPage = 5;

  const sortedTestReports = [...testreports].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "desc" ? 1 : -1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "desc" ? -1 : 1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    setSortConfig({ key, direction });
  };

  const indexOfLastTestReport = currentPage * testreportsPerPage;
  const indexOfFirstTestReport = indexOfLastTestReport - testreportsPerPage;
  const currentTestReports = sortedTestReports.slice(
    indexOfFirstTestReport,
    indexOfLastTestReport,
  );

  const getSignedUrl = async (key) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/docker/generate-signed-url`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { key },
        },
      );
      return response.data.url;
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching test result URL:", error);
      }
    }
  };

  const getAzureSignedUrl = async (key) => {
    try {
      const response = await axios.post(
        `${conf.API_BASE_URL}/docker/generate-Azure-signed-url`,
        { key },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      return response.data.signedUrl;
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching test result URL:", error);
      }
    }
  };

  const handleClick = async (urlKey) => {
    try {
      if (!urlKey) {
        setErrorMessage("No URL");
        return;
      }

      const urls = urlKey.split(";");

      for (const url of urls) {
        try {
          if (
            !url.includes("blob.core.windows.net") &&
            (url.startsWith("http:") || url.startsWith("https:"))
          ) {
            window.open(url, "_blank");
          } else if (
            url.includes("blob.core.windows.net") &&
            (url.startsWith("http:") || url.startsWith("https:"))
          ) {
            const blobUrl = await getAzureSignedUrl(url);
            window.open(blobUrl, "_blank");
          } else {
            const signedUrl = await getSignedUrl(url);
            window.open(signedUrl, "_blank");
          }
        } catch (error) {
          console.error("Error handling URL click:", error);
        }
      }
    } catch (error) {
      console.error("Error handling URL click:", error);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return (
      date.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        day: "2-digit",
        month: "short",
        year: "numeric",
        timeZone: "UTC", // Ensure the date is interpreted as UTC
      }) + " UTC"
    ); // Append " UTC" to the formatted date
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="testreport-list">
      <h2>TestReport</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {Array.isArray(testreports) && testreports.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort("name")}>Name</th>
                <th onClick={() => handleSort("reportdate")}>Report Date</th>
                <th onClick={() => handleSort("testenv")}>Test Env</th>
              </tr>
            </thead>
            <tbody>
              {currentTestReports.map((testreport) => (
                <tr key={testreport.objid}>
                  <td>
                    <Link to={`/testreport/${testreport.objid}`}>
                      {testreport.name || "Unnamed TestReport"}
                    </Link>
                  </td>
                  <td>{formatDate(testreport.reportdate)}</td>
                  <td>{testreport.testenv}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(testreports.length / testreportsPerPage) },
              (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ),
            )}
          </div>
        </>
      ) : (
        <p>No testreports available</p>
      )}
    </div>
  );
};

export default TestReportList;
