import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import debounce from "lodash.debounce";
import "./DynamicForm.css"; // Import the CSS file
import { AuthContext } from "../context/AuthContext";
import conf from "../config/config";
import { AWS_customfields, AZURE_customfields } from "./CloudConfig";

const DynamicForm = ({
  tableName,
  fields,
  customFields,
  formData: initialFormData,
  onSubmit,
  onCancel,
}) => {
  const { user, logout } = useContext(AuthContext);
  const [formData, setFormData] = useState(initialFormData);
  const [customData, setCustomData] = useState({});
  const [customFieldsState, setCustomFieldsState] = useState(customFields);
  const [files, setFiles] = useState({});
  const [custfiles, setCustomFiles] = useState({});
  const [options, setOptions] = useState({});
  const [errors, setErrors] = useState({});

  console.log("formData=" + JSON.stringify(formData));

  useEffect(() => {
    fields.forEach((field) => {
      if (
        field.type === "select" &&
        field.fetchOptions &&
        !options[field.name]
      ) {
        fetchOptions(field.name, field.fetchOptions);
      }
    });
  }, [fields, options]);

  const fetchOptions = async (fieldName, url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          x_account_id: `${user.x_account_id}`,
          x_groupuser_id: `${user.x_groupuserid}`,
          x_poolindex: `${user.x_poolindex}`,
        },
      });

      if (Array.isArray(response.data)) {
        setOptions((prevOptions) => ({
          ...prevOptions,
          [fieldName]: response.data.map((item) => ({
            value: item.objid || item.propertyvalue || item.codevalue,
            label: item.name || item.propertystring,
          })),
        }));
      } else {
        console.error(`Expected an array but got:`, response.data);
      }
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error(`Error fetching options for ${fieldName}:`, error);
      }
    }
  };

  const debouncedFetchOptions = useRef(
    debounce(
      (fieldName, url, query) =>
        fetchOptions(fieldName, `${url}?query=${query}`),
      300
    )
  ).current;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateLength(name, value);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked ? "true" : "false",
    });
  };

  const handleSelectChange = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        [fieldName]: selectedOption.value,
      });

      if (tableName === "cloudconfig" && selectedOption.value === "AWS") {
        setCustomFieldsState(AWS_customfields);
      } else if (
        tableName === "cloudconfig" &&
        selectedOption.value === "Azure"
      ) {
        setCustomFieldsState(AZURE_customfields);
      }
    } else {
      setFormData({
        ...formData,
        [fieldName]: "",
      });
    }
  };

  const handleCustomChange = (e) => {
    const { name, value } = e.target;
    setCustomData((prevCustomData) => ({
      ...prevCustomData,
      [name]: value,
    }));
    validateLength(name, value);
  };

  const handleCustomFileChange = (e) => {
    const { name, files } = e.target;
    setCustomFiles({
      ...custfiles,
      [name]: files[0],
    });
  };

  const handleCustomCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCustomData((prevCustomData) => ({
      ...prevCustomData,
      [name]: checked,
    }));
  };

  const handleCustomSelectChange = (fieldName, selectedOption) => {
    setCustomData((prevCustomData) => ({
      ...prevCustomData,
      [fieldName]: selectedOption ? selectedOption.value : "",
    }));
  };

  const validateLength = (name, value) => {
    const field =
      fields.find((f) => f.name === name) ||
      customFields.find((f) => f.name === name);
    if (field && field.maxLength && value.length > field.maxLength) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `Maximum length is ${field.maxLength} characters`,
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: files[0], // Ensure file is set correctly
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0].name, // Store file name in formData for validation
    }));
  };
  const validateForm = () => {
    const newErrors = {};

    fields.forEach((field) => {
      if (field.required && !formData[field.name]) {
        newErrors[field.name] = `${field.label} is required`;
      }
    });

    customFields.forEach((field) => {
      if (field.required && !customData[field.name]) {
        newErrors[field.name] = `${field.label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append("document", file);

    const response = await axios.post(
      `${conf.API_BASE_URL}/files/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
          x_account_id: `${user.x_account_id}`,
          x_groupuser_id: `${user.x_groupuserid}`,
          x_poolindex: `${user.x_poolindex}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data.url; // Assuming the URL is returned in response.data.url
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    try {
      const dataToSubmit = {
        ...formData,
        customdata: JSON.stringify(customData),
      };

      const formDataToSubmit = new FormData();

      // Upload main files to S3 and get their URLs
      for (const key in files) {
        const file = files[key];
        if (file) {
          const fileUrl = await uploadFileToS3(file);
          dataToSubmit["url"] = fileUrl; // Ensure the URL is assigned to the "url" key
        }
      }

      // Upload custfiles to S3 and get their URLs
      for (const key in custfiles) {
        const file = custfiles[key];
        if (file) {
          const fileUrl = await uploadFileToS3(file);
          dataToSubmit["url"] = fileUrl; // Ensure the URL is assigned to the "url" key
        }
      }

      // Append all form data to formDataToSubmit
      for (const key in dataToSubmit) {
        if (dataToSubmit[key] !== null && dataToSubmit[key] !== undefined) {
          formDataToSubmit.append(key, dataToSubmit[key]);
        }
      }

      const response = await axios.post(
        `${conf.API_BASE_URL}/${tableName}s/save`,
        formDataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        }
      );
      console.log("Response data:", response.data);
      onSubmit(response);
    } catch (error) {
      console.error("Error creating record:", error);
      // Optionally, provide user feedback here
    }
  };
  const renderField = (field) => {
    const errorMessage = errors[field.name] ? (
      <span className="error-message">{errors[field.name]}</span>
    ) : null;

    const commonProps = {
      name: field.name,
      required: field.required,
      className: errors[field.name] ? "error-input" : "",
      placeholder: field.label,
      readOnly: field.fieldtype === "readonly" || false, // Set field as readonly if fieldtype is "readonly"
      style: field.fieldtype === "hidden" ? { display: "none" } : {},
    };

    if (field.type === "file") {
      return (
        <div>
          <input type="file" onChange={handleFileChange} {...commonProps} />
          {errorMessage}
        </div>
      );
    } else if (field.type === "select") {
      return (
        <div>
          <Select
            value={
              options[field.name]?.find(
                (option) => option.value === formData[field.name]
              ) || ""
            }
            onInputChange={(inputValue) => {
              if (typeof inputValue === "string" && field.name.includes("2")) {
                debouncedFetchOptions(
                  field.name,
                  field.fetchOptions,
                  inputValue
                );
              }
            }}
            onChange={(selectedOption) => {
              handleSelectChange(field.name, selectedOption);
              if (field.autopopulate && selectedOption) {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  [field.autopopulate]: selectedOption.label,
                }));
              }
            }}
            options={options[field.name]}
            isClearable
            isSearchable={field.name.includes("2")}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else if (field.type === "checkbox") {
      return (
        <div>
          <input
            type="checkbox"
            checked={formData[field.name] === "true"}
            onChange={handleCheckboxChange}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else if (field.maxLength && field.maxLength >= 300) {
      return (
        <div>
          <textarea
            maxLength={field.maxLength}
            className={`scrollable-textarea ${
              errors[field.name] ? "error-input" : ""
            }`}
            rows="4"
            onChange={handleChange}
            value={formData[field.name] || ""}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else if (["date", "datetime", "time"].includes(field.type)) {
      return (
        <div>
          <input
            type={field.type}
            onChange={handleChange}
            value={formData[field.name] || ""}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else if (
      ["number", "float", "decimal", "int", "integer"].includes(field.type)
    ) {
      return (
        <div>
          <input
            type="number"
            onChange={handleChange}
            value={formData[field.name] || ""}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else {
      return (
        <div>
          <input
            type={field.type}
            onChange={handleChange}
            value={formData[field.name] || ""}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    }
  };

  const renderCustomField = (field) => {
    const errorMessage = errors[field.name] ? (
      <span className="error-message">{errors[field.name]}</span>
    ) : null;

    const isRequired = field.isrequired === "true";
    const isEditable = field.iseditable === "true";

    const commonProps = {
      name: field.name,
      required: isRequired,
      className: errors[field.name] ? "error-input" : "",
      placeholder: field.label,
      disabled: !isEditable,
    };

    const validateFieldType = (type) => {
      const validTypes = [
        "date",
        "datetime",
        "time",
        "integer",
        "number",
        "float",
        "int",
        "decimal",
      ];
      return validTypes.includes(type);
    };

    if (field.fieldtype === "file") {
      return (
        <div>
          <input
            type="file"
            onChange={handleCustomFileChange}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else if (field.fieldtype === "select") {
      return (
        <div>
          <Select
            value={
              options[field.name]?.find(
                (option) => option.value === customData[field.name]
              ) || ""
            }
            onInputChange={(inputValue) => {
              if (typeof inputValue === "string" && field.name.includes("2")) {
                debouncedFetchOptions(
                  field.name,
                  field.fetchOptions,
                  inputValue
                );
              }
            }}
            onChange={(selectedOption) =>
              handleCustomSelectChange(field.name, selectedOption)
            }
            options={options[field.name]}
            isClearable
            isSearchable={field.name.includes("2")}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else if (field.fieldtype === "checkbox") {
      return (
        <div>
          <input
            type="checkbox"
            checked={customData[field.name] === "true"}
            onChange={handleCustomCheckboxChange}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else if (field.maxlength && field.maxlength >= 300) {
      return (
        <div>
          <textarea
            maxLength={field.maxlength}
            className={`scrollable-textarea wide-textarea ${errors[field.name] ? "error-input" : ""}`}
            onChange={handleCustomChange}
            rows="4"
            value={customData[field.name] || ""}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else if (validateFieldType(field.fieldtype)) {
      return (
        <div>
          <input
            type={field.fieldtype}
            onChange={handleCustomChange}
            value={customData[field.name] || ""}
            maxLength={field.maxlength}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    } else {
      return (
        <div>
          <input
            type="text"
            onChange={handleCustomChange}
            value={customData[field.name] || ""}
            maxLength={field.maxlength}
            {...commonProps}
          />
          {errorMessage}
        </div>
      );
    }
  };

  const renderUpto300 = (field1, field2) => (
    <div key={field1.name} className="form-row">
      <div className="half-width">
        <div className="form-group">
          {field1.fieldtype !== "hidden" && <label>{field1.label}:</label>}
          {renderField(field1)}
        </div>
      </div>
      {field2 && (
        <div className="half-width">
          <div className="form-group">
            {field2.fieldtype !== "hidden" && <label>{field2.label}:</label>}
            {renderField(field2)}
          </div>
        </div>
      )}
    </div>
  );

  const render300Plus = (field) => (
    <div key={field.name} className="form-row">
      <div className="full-width">
        <div className="form-group">
          <label>{field.label}:</label>
          {renderField(field)}
        </div>
      </div>
    </div>
  );

  const renderCustomFieldUpto300 = (field1, field2) => (
    <div key={field1.name} className="form-row">
      <div className="half-width">
        <div className="form-group">
          <label>{field1.label}:</label>
          {renderCustomField(field1)}
        </div>
      </div>
      {field2 && (
        <div className="half-width">
          <div className="form-group">
            <label>{field2.label}:</label>
            {renderCustomField(field2)}
          </div>
        </div>
      )}
    </div>
  );

  const renderCustomField300Plus = (field) => (
    <div key={field.name} className="form-row">
      <div className="full-width">
        <div className="form-group">
          <label>{field.label}:</label>
          {renderCustomField(field)}
        </div>
      </div>
    </div>
  );

  let fld = null;
  return (
    <form onSubmit={handleSubmit} className="dynamic-form">
      {fields.map((field, index) => {
        if (field.maxLength && field.maxLength >= 300) {
          fld = field;
          return render300Plus(field);
        } else if (field.fieldtype === "hidden") {
          fld = field;
          renderField(field);
        } else if (fld !== field) {
          fld =
            fields[index + 1] && fields[index + 1].maxLength < 300
              ? fields[index + 1]
              : null;
          if (fld) {
            return renderUpto300(field, fld);
          } else {
            return render300Plus(field);
          }
        }
        return null;
      })}

      {customFieldsState.length > 0 && (
        <>
          <h2>Custom Data</h2>
          {customFieldsState.map((field, index) => {
            if (field.maxLength && field.maxLength >= 300) {
              fld = field;
              return renderCustomField300Plus(field);
            } else if (fld !== field) {
              fld =
                customFieldsState[index + 1] &&
                customFieldsState[index + 1].maxLength < 300
                  ? customFieldsState[index + 1]
                  : null;

              if (fld) {
                return renderCustomFieldUpto300(field, fld);
              } else {
                return renderCustomField300Plus(field);
              }
            }
            return null;
          })}
        </>
      )}

      <div className="button-group">
        <button type="submit" className="submit-button">
          Submit
        </button>
        <button type="button" onClick={onCancel} className="submit-button">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default DynamicForm;
