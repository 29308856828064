import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "emailhost",
    label: "Email Host",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "portno",
    label: "Port Number",
    type: "integer",
    maxLength: 16,
    required: true,
  },
  {
    name: "senderemail",
    label: "Sender Email",
    type: "email",
    maxLength: 100,
    required: true,
  },
  {
    name: "commonmessage",
    label: "Message Body (4000 letters)",
    type: "text",
    maxLength: 4000,
    required: true,
  },
  {
    name: "messagefooter",
    label: "Message Footer",
    type: "text",
    maxLength: 4000,
    required: true,
  },
];
const EmailSettingFormContainer = ({
  propEmailSetting,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propEmailSetting?.objid || null,
    name: propEmailSetting?.name || null,
    emailhost: propEmailSetting?.emailhost || null,
    portno: propEmailSetting?.portno || null,
    senderemail: propEmailSetting?.senderemail || null,
    commonmessage: propEmailSetting?.commonmessage || null,
    messagefooter: propEmailSetting?.messagefooter || null,
    customdata: propEmailSetting?.customdata || null,
    groupuserid: propEmailSetting?.x_groupuserid || user.x_groupuserid,
    createdbyid: propEmailSetting?.createdbyid || user.id,
    createdby: propEmailSetting?.createdby || user.fullname,
    createddate: propEmailSetting?.createddate
      ? formatDate(propEmailSetting.createddate)
      : today,
    modifiedby: propEmailSetting?.modifiedby || user.fullname,
    modifieddate: propEmailSetting?.modifieddate
      ? formatDate(propEmailSetting.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "EmailSetting", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="emailsetting"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default EmailSettingFormContainer;
