import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import conf from "../config/config";
import { AuthContext } from "../context/AuthContext";
import "./TimesheetSummary.css"; // Import CSS for styling

const TimesheetSummary = ({ assigneeId }) => {
  const { user, logout } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      // Ensure assigneeId is set to the user’s ID if it’s not provided
      if (!assigneeId) {
        assigneeId = user.id;
      }

      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/summary/getTimesheetSummaryByWeek/${assigneeId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          }
        );
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setErrorMessage(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [assigneeId, user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="timesheet-list">
      <h2>Weekly Timesheet</h2>
      {error && <div className="error-message">{error}</div>}
      {Array.isArray(data) && data.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Week</th>
              <th>Submitted Time</th>
              <th>Weekly Approved Time</th>
              <th>Pending Submission</th>
              <th>Pending Approval</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              const isGreen = item.totalsubmitted === item.totalapproved;
              //   &&    item.totalnotsubmitted === 0 &&
              // item.totalnotapproved === 0;

              return (
                <tr
                  key={item.weekstartdate}
                  className={isGreen ? "green-row" : "yellow-row"}
                >
                  <td>{item.name}</td>
                  <td>{item.weekstartdate}</td>
                  <td>{item.totalsubmitted}</td>
                  <td>{item.totalapproved}</td>
                  <td>{item.totalnotsubmitted}</td>
                  <td>{item.totalnotapproved}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No Timesheet Submitted or Approved</div>
      )}
    </div>
  );
};

export default TimesheetSummary;
