import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditMember.css";
import TableMember from "./TableMember";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import BoardTasks from "../../common/BoardTasks";
import TimesheetSummary from "../../common/TimesheetSummary";
import ApproveTimesheet from "../../common/ApproveTimesheet";

const EditMember = ({ memberId: propMemberId }) => {
  const [member, setMember] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableMember, setShowTableMember] = useState(false);
  const [timesheets, setTimesheets] = useState([]);

  const { id } = useParams();
  const memberId = id || propMemberId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchMember = async () => {
        if (!memberId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/members/searchById/${memberId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setMember(response.data);
            setEditLoad(true);
            console.log("Fetched member data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching member:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchMember();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/timesheet?query=${memberId}&field=timesheet2member`,
        setTimesheets,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, memberId]);

  const handleMemberSubmit = (newMember) => {
    navigate(`/member/${newMember.objid}`);
  };

  const handleTimesheetSubmit = (newTimesheet) => {
    setTimesheets((timesheets) => [newTimesheet, ...timesheets]);
    setShowTableMember(true);
  };
  return (
    <div className="edit-member-container">
      <div className="main-content">
        <h1>Edit Member</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, member)}
        {editLoad ? (
          <TableMember
            data={member}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <BoardTasks inputId={"memberid"} inputValue={memberId} />
        <TimesheetSummary assigneeId={memberId} />
        <ApproveTimesheet memberId={memberId} />
      </div>
      <RightSidebar
        data={member}
        onTimesheetSubmit={handleTimesheetSubmit}
        onMemberSubmit={handleMemberSubmit}
      />
    </div>
  );
};

export default EditMember;
