import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";
import { AWS_customfields } from "../../common/CloudConfig";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Cloud Name",
    type: "select",
    maxLength: 100,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Name&table=CloudConfig`,
  },
];
const CloudConfigFormContainer = ({
  propCloudConfig,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propCloudConfig?.objid || null,
    name: propCloudConfig?.name || null,
    customdata: propCloudConfig?.customdata || null,
    groupuserid: propCloudConfig?.x_groupuserid || user.x_groupuserid,
    createdbyid: propCloudConfig?.createdbyid || user.id,
    createdby: propCloudConfig?.createdby || user.fullname,
    createddate: propCloudConfig?.createddate
      ? formatDate(propCloudConfig.createddate)
      : today,
    modifiedby: propCloudConfig?.modifiedby || user.fullname,
    modifieddate: propCloudConfig?.modifieddate
      ? formatDate(propCloudConfig.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        setCustomFields(AWS_customfields);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="cloudconfig"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default CloudConfigFormContainer;
