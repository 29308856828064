import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Tag Name",
    type: "text",
    maxLength: 100,
    required: true,
    fieldtype: "readonly",
  },
  {
    name: "suitetag2testsuite",
    maxLength: 100,
    label: "TestSuite",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/testsuite`,
  },
  {
    name: "suitetag2tag",
    maxLength: 100,
    label: "Tag",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/tag`,
    autopopulate: "name",
  },
];
const SuiteTagFormContainer = ({
  propSuiteTag,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propSuiteTag?.objid || null,
    name: propSuiteTag?.name || null,
    lastrun: propSuiteTag?.lastrun || null,
    suitetag2testsuite: propSuiteTag?.suitetag2testsuite,
    suitetag2tag: propSuiteTag?.suitetag2tag,
    customdata: propSuiteTag?.customdata || null,
    groupuserid: propSuiteTag?.x_groupuserid || user.x_groupuserid,
    createdbyid: propSuiteTag?.createdbyid || user.id,
    createdby: propSuiteTag?.createdby || user.fullname,
    createddate: propSuiteTag?.createddate
      ? formatDate(propSuiteTag.createddate)
      : today,
    modifiedby: propSuiteTag?.modifiedby || user.fullname,
    modifieddate: propSuiteTag?.modifieddate
      ? formatDate(propSuiteTag.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "SuiteTag", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="suitetag"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default SuiteTagFormContainer;
