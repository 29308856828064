import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "business",
    label: "Business",
    type: "text",
    maxLength: 50,
    required: false,
  },
  {
    name: "contactname",
    label: "Contact",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "phone",
    label: "Phone",
    type: "text",
    maxLength: 20,
    required: true,
  },
  {
    name: "mobileno",
    label: "Mobile",
    type: "text",
    maxLength: 20,
    required: false,
  },
  {
    name: "street",
    label: "Street",
    type: "text",
    maxLength: 100,
    required: false,
  },
  {
    name: "zipcode",
    label: "Zip Code",
    type: "text",
    maxLength: 10,
    required: false,
  },
  {
    name: "state",
    label: "State",
    type: "text",
    maxLength: 30,
    required: false,
  },
  {
    name: "country",
    label: "Country",
    type: "text",
    maxLength: 50,
    required: false,
  },
];
const AccountFormContainer = ({
  propAccount,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propAccount?.objid || null,
    name: propAccount?.name || null,
    useraccountid: propAccount?.useraccountid || null,
    business: propAccount?.business || null,
    contactname: propAccount?.contactname || null,
    email: propAccount?.email || null,
    phone: propAccount?.phone || null,
    mobileno: propAccount?.mobileno || null,
    street: propAccount?.street || null,
    zipcode: propAccount?.zipcode || null,
    state: propAccount?.state || null,
    country: propAccount?.country || null,
    customdata: propAccount?.customdata || null,
    groupuserid: propAccount?.x_groupuserid || user.x_groupuserid,
    createdbyid: propAccount?.createdbyid || user.id,
    createdby: propAccount?.createdby || user.fullname,
    createddate: propAccount?.createddate
      ? formatDate(propAccount.createddate)
      : today,
    modifiedby: propAccount?.modifiedby || user.fullname,
    modifieddate: propAccount?.modifieddate
      ? formatDate(propAccount.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Account", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="account"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default AccountFormContainer;
