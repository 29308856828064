import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./ListingPage.css";
import axios from "axios";
import conf from "../config/config";
import { AuthContext } from "../context/AuthContext";

const RunTagList = ({ runtags }) => {
  const { user, logout } = useContext(AuthContext);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const runtagsPerPage = 5;

  const sortedRunTags = [...runtags].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const indexOfLastRunTag = currentPage * runtagsPerPage;
  const indexOfFirstRunTag = indexOfLastRunTag - runtagsPerPage;
  const currentRunTags = sortedRunTags.slice(
    indexOfFirstRunTag,
    indexOfLastRunTag
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRun = async (objid) => {
    try {
      setMessage("Please wait until we start the test cycle!");
      const response = await axios.post(
        `${conf.API_BASE_URL}/docker/run-testcycle`,
        {
          runid: objid,
          userid: user.id,
          runby: user.fullname,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        }
      );

      if (response.status === 200) {
        setMessage("Test Cycle started successfully!");
      } else {
        setError("Failed to start TestCycle." + response.data.error);
      }
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error initiating TestCycle:", error);
        setError("Error initiating TestCycle." + error.response.data.error);
      }
    }
  };

  return (
    <div className="runtag-list">
      <h2>RunTag</h2>
      {message && <p>{message}</p>}
      {error && <p className="error-message">{error}</p>}
      {Array.isArray(runtags) && runtags.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort("name")}>RunTag</th>
                <th onClick={() => handleSort("lastrun")}>Last Run</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentRunTags.map((runtag) => (
                <tr key={runtag.objid}>
                  <td>
                    <Link to={`/runtag/${runtag.objid}`}>
                      {runtag.name || "Unnamed RunTag"}
                    </Link>
                  </td>
                  <td>
                    {runtag.lastrun
                      ? new Date(runtag.lastrun).toLocaleString()
                      : ""}
                  </td>
                  <td>
                    <button onClick={() => handleRun(runtag.objid)}>Run</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(runtags.length / runtagsPerPage) },
              (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </>
      ) : (
        <p>No runtags available</p>
      )}
    </div>
  );
};

export default RunTagList;
