import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditAttribute.css";
import TableAttribute from "./TableAttribute";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import ListPropertyList from "../listproperty/ListPropertyList";

const EditAttribute = ({ attributeId: propAttributeId }) => {
  const [attribute, setAttribute] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableAttribute, setShowTableAttribute] = useState(false);
  const [listpropertys, setListPropertys] = useState([]);

  const { id } = useParams();
  const attributeId = id || propAttributeId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchAttribute = async () => {
        if (!attributeId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/attributes/searchById/${attributeId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setAttribute(response.data);
            setEditLoad(true);
            console.log("Fetched attribute data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching attribute:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchAttribute();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/listproperty?query=${attributeId}&field=listproperty2attribute`,
        setListPropertys,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, attributeId]);

  const handleAttributeSubmit = (newAttribute) => {
    navigate(`/attribute/${newAttribute.objid}`);
  };

  const handleListPropertySubmit = (newListProperty) => {
    setListPropertys((listpropertys) => [newListProperty, ...listpropertys]);
    setShowTableAttribute(true);
  };
  return (
    <div className="edit-attribute-container">
      <div className="main-content">
        <h1>Edit Attribute</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, attribute)}
        {editLoad ? (
          <TableAttribute
            data={attribute}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <ListPropertyList listpropertys={listpropertys} />
      </div>
      <RightSidebar
        data={attribute}
        onListPropertySubmit={handleListPropertySubmit}
        onAttributeSubmit={handleAttributeSubmit}
      />
    </div>
  );
};

export default EditAttribute;
