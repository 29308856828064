import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "attributename",
    label: "Attrubute Name",
    type: "select",
    maxLength: 100,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=AttributeName&table=GenericCode`,
  },
  {
    name: "codevalue",
    label: "Code Value",
    type: "text",
    maxLength: 30,
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 150,
    required: false,
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Status&table=GenericCode`,
  },
  {
    name: "codeindex",
    label: "Code Index",
    type: "integer",
    maxLength: 16,
    required: true,
  },
  {
    name: "genericcode2codeattribute",
    maxLength: 100,
    label: "CodeAttribute",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/codeattribute`,
  },
];
const GenericCodeFormContainer = ({
  propGenericCode,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propGenericCode?.objid || null,
    name: propGenericCode?.name || null,
    attributename: propGenericCode?.attributename || null,
    codevalue: propGenericCode?.codevalue || null,
    description: propGenericCode?.description || null,
    status: propGenericCode?.status || null,
    codeindex: propGenericCode?.codeindex || null,
    genericcode2codeattribute: propGenericCode?.genericcode2codeattribute,
    customdata: propGenericCode?.customdata || null,
    groupuserid: propGenericCode?.x_groupuserid || user.x_groupuserid,
    createdbyid: propGenericCode?.createdbyid || user.id,
    createdby: propGenericCode?.createdby || user.fullname,
    createddate: propGenericCode?.createddate
      ? formatDate(propGenericCode.createddate)
      : today,
    modifiedby: propGenericCode?.modifiedby || user.fullname,
    modifieddate: propGenericCode?.modifieddate
      ? formatDate(propGenericCode.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "GenericCode", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="genericcode"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default GenericCodeFormContainer;
