import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import conf from "../config/config";
import "./MyFeatures.css"; // Import the new CSS file

const FeatureList = () => {
  const [features, setFeatures] = useState([]);
  const [page, setPage] = useState(1);
  const [totalFeatures, setTotalFeatures] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const { user } = useContext(AuthContext);
  const limit = 10; // Number of features per page

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await axios.post(
          `${conf.API_BASE_URL}/dashboard/getMyFeatures`,
          {
            tasks2assignee: user.id,
            createdby: user.fullname,
            page,
            limit,
            sortKey: sortConfig.key,
            sortDirection: sortConfig.direction,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          }
        );
        setFeatures(response.data.features);
        setTotalFeatures(response.data.totalFeatures);
      } catch (error) {
        console.error("Error fetching features:", error);
      }
    };

    fetchFeatures();
  }, [page, limit, sortConfig, user]);

  const totalPages = Math.ceil(totalFeatures / limit);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="dashboard-content">
      <h2>My Features</h2>
      {features.length === 0 ? (
        <p>No Features Available</p>
      ) : (
        <>
          <table className="feature-table">
            <thead>
              <tr>
                <th onClick={() => requestSort("name")}>Name</th>
                <th onClick={() => requestSort("priority")}>Priority</th>
                <th onClick={() => requestSort("taskcount")}>Task Count</th>
                <th onClick={() => requestSort("taskcomplete")}>
                  Task Completed
                </th>
                <th onClick={() => requestSort("status")}>Status</th>
              </tr>
            </thead>
            <tbody>
              {features.map((feature) => (
                <tr key={feature.objid}>
                  <td>
                    <Link to={`/feature/${feature.objid}`}>{feature.name}</Link>
                  </td>
                  <td>{feature.priority}</td>
                  <td>{feature.taskcount}</td>
                  <td>{feature.taskcomplete}</td>
                  <td>{feature.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => setPage(index + 1)}
                disabled={page === index + 1}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FeatureList;
