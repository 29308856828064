import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "projecttag",
    label: "Project Tag",
    type: "text",
    maxLength: 30,
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 1000,
    required: false,
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Status&table=Project`,
  },
  {
    name: "startdate",
    label: "Start Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "codecomplete",
    label: "Code Complete Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "testcomplete",
    label: "Test Complete Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "projecttype",
    label: "Project Type",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=ProjectType&table=Project`,
  },
  {
    name: "repositoryurl",
    label: "Repository URL",
    type: "text",
    maxLength: 200,
    required: false,
  },
  {
    name: "ishiring",
    label: "Is Hiring",
    type: "checkbox",
    maxLength: 3,
    required: false,
  },
  {
    name: "project2product",
    maxLength: 100,
    label: "Product",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/product`,
  },
];
const ProjectFormContainer = ({
  propProject,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propProject?.objid || null,
    name: propProject?.name || null,
    projecttag: propProject?.projecttag || null,
    description: propProject?.description || null,
    status: propProject?.status || null,
    startdate: propProject?.startdate || null,
    codecomplete: propProject?.codecomplete || null,
    testcomplete: propProject?.testcomplete || null,
    projecttype: propProject?.projecttype || null,
    repositoryurl: propProject?.repositoryurl || null,
    ishiring: propProject?.ishiring || null,
    project2product: propProject?.project2product,
    customdata: propProject?.customdata || null,
    groupuserid: propProject?.x_groupuserid || user.x_groupuserid,
    createdbyid: propProject?.createdbyid || user.id,
    createdby: propProject?.createdby || user.fullname,
    createddate: propProject?.createddate
      ? formatDate(propProject.createddate)
      : today,
    modifiedby: propProject?.modifiedby || user.fullname,
    modifieddate: propProject?.modifieddate
      ? formatDate(propProject.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Project", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="project"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default ProjectFormContainer;
