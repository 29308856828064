import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 200, required: true },
  {
    name: "scenariotag",
    label: "Scenario Tag",
    type: "text",
    maxLength: 30,
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 4000,
    required: false,
  },
  {
    name: "isbackground",
    label: "Is Background",
    type: "checkbox",
    maxLength: 3,
    required: false,
  },
  {
    name: "stepdetail",
    label: "Steps",
    type: "text",
    maxLength: 4000,
    required: true,
  },
  {
    name: "exampledata",
    label: "Example Data",
    type: "text",
    maxLength: 4000,
    required: false,
  },
  {
    name: "isautomated",
    label: "Is Automated",
    type: "select",
    maxLength: 3,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=IsAutomated&table=Scenario`,
  },
  {
    name: "isactive",
    label: "Is Active",
    type: "select",
    maxLength: 3,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=IsActive&table=Scenario`,
  },
  {
    name: "priority",
    label: "Priority",
    type: "select",
    maxLength: 50,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Priority&table=Scenario`,
  },
  {
    name: "externid",
    label: "External Id",
    type: "text",
    maxLength: 30,
    required: false,
  },
  {
    name: "scenario2feature",
    maxLength: 100,
    label: "Feature",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/feature`,
  },
];
const ScenarioFormContainer = ({
  propScenario,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propScenario?.objid || null,
    name: propScenario?.name || null,
    scenariotag: propScenario?.scenariotag || null,
    description: propScenario?.description || null,
    isbackground: propScenario?.isbackground || "false",
    stepdetail: propScenario?.stepdetail || null,
    exampledata: propScenario?.exampledata || null,
    isautomated: propScenario?.isautomated || null,
    isactive: propScenario?.isactive || null,
    priority: propScenario?.priority || null,
    externid: propScenario?.externid || null,
    scenario2feature: propScenario?.scenario2feature,
    customdata: propScenario?.customdata || null,
    groupuserid: propScenario?.x_groupuserid || user.x_groupuserid,
    createdbyid: propScenario?.createdbyid || user.id,
    createdby: propScenario?.createdby || user.fullname,
    createddate: propScenario?.createddate
      ? formatDate(propScenario.createddate)
      : today,
    modifiedby: propScenario?.modifiedby || user.fullname,
    modifieddate: propScenario?.modifieddate
      ? formatDate(propScenario.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Scenario", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="scenario"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default ScenarioFormContainer;
