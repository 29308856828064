import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "role",
    label: "Role",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Role&table=Member`,
  },
  {
    name: "percenttime",
    label: "Percentage (%)",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=PercentTime&table=Member`,
  },
  {
    name: "hourlyrate",
    label: "Hourly Rate",
    type: "float",
    maxLength: 7,
    required: true,
  },
  {
    name: "resourcetype",
    label: "Resource Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=ResourceType&table=Member`,
  },
  {
    name: "permission",
    label: "Access Permission",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Permission&table=Member`,
  },
  {
    name: "startdate",
    label: "Start Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "enddate",
    label: "End Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "holidayhours",
    label: "Holiday Hrs",
    type: "integer",
    maxLength: 16,
    required: true,
  },
  {
    name: "vacationtime",
    label: "Vacation Hrs",
    type: "integer",
    maxLength: 16,
    required: true,
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Status&table=Member`,
  },
  {
    name: "member2board",
    maxLength: 100,
    label: "Board",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/board`,
  },
  {
    name: "member2assignee",
    maxLength: 100,
    label: "Assignee",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/assignee`,
  },
];
const MemberFormContainer = ({ propMember, onSubmit, onCancel, cloneData }) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propMember?.objid || null,
    name: propMember?.name || null,
    role: propMember?.role || null,
    percenttime: propMember?.percenttime || "100",
    hourlyrate: propMember?.hourlyrate || "0",
    resourcetype: propMember?.resourcetype || null,
    permission: propMember?.permission || null,
    startdate: propMember?.startdate || null,
    enddate: propMember?.enddate || null,
    holidayhours: propMember?.holidayhours || "0",
    vacationtime: propMember?.vacationtime || "0",
    status: propMember?.status || null,
    poolindex: propMember?.poolindex || user.x_poolindex,
    member2board: propMember?.member2board,
    member2assignee: propMember?.member2assignee,
    customdata: propMember?.customdata || null,
    groupuserid: propMember?.x_groupuserid || user.x_groupuserid,
    createdbyid: propMember?.createdbyid || user.id,
    createdby: propMember?.createdby || user.fullname,
    createddate: propMember?.createddate
      ? formatDate(propMember.createddate)
      : today,
    modifiedby: propMember?.modifiedby || user.fullname,
    modifieddate: propMember?.modifieddate
      ? formatDate(propMember.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Member", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="member"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default MemberFormContainer;
