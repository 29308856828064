import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import conf from "../config/config";
import "./MyDefects.css"; // Import the new CSS file

const DefectList = () => {
  const [defects, setDefects] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDefects, setTotalDefects] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const { user } = useContext(AuthContext);
  const limit = 10; // Number of defects per page

  useEffect(() => {
    const fetchDefects = async () => {
      try {
        const response = await axios.post(
          `${conf.API_BASE_URL}/dashboard/getMyDefects`,
          {
            defect2assignee: user.id,
            createdby: user.fullname,
            page,
            limit,
            sortKey: sortConfig.key,
            sortDirection: sortConfig.direction,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          }
        );
        setDefects(response.data.defects);
        setTotalDefects(response.data.totalDefects);
      } catch (error) {
        console.error("Error fetching defects:", error);
      }
    };

    fetchDefects();
  }, [page, limit, sortConfig, user]);

  const totalPages = Math.ceil(totalDefects / limit);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="dashboard-content">
      <h2>My Defects</h2>
      {defects.length === 0 ? (
        <p>No Defects Available</p>
      ) : (
        <>
          <table className="defect-table">
            <thead>
              <tr>
                <th onClick={() => requestSort("name")}>Name</th>
                <th onClick={() => requestSort("creationdate")}>
                  Creation Date
                </th>
                <th onClick={() => requestSort("status")}>Status</th>
                <th onClick={() => requestSort("severity")}>Severity</th>
                <th onClick={() => requestSort("priority")}>Priority</th>
              </tr>
            </thead>
            <tbody>
              {defects.map((defect) => (
                <tr key={defect.objid}>
                  <td>
                    <Link to={`/defect/${defect.objid}`}>{defect.name}</Link>
                  </td>
                  <td>{formatDate(defect.creationdate)}</td>
                  <td>{defect.status}</td>
                  <td>{defect.severity}</td>
                  <td>{defect.priority}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => setPage(index + 1)}
                disabled={page === index + 1}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return (
    date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      day: "2-digit",
      month: "short",
      year: "numeric",
      timeZone: "UTC", // Ensure the date is interpreted as UTC
    }) + " UTC"
  ); // Append " UTC" to the formatted date
};

export default DefectList;
