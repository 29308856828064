export const AWS_customfields = [
  {
    name: "awsaccountid",
    label: "AWS Account Id",
    fieldtype: "text",
    maxlength: 50,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "awsregion",
    label: "Region",
    fieldtype: "text",
    maxlength: 50,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "access_key_id",
    label: "Access Key Id",
    fieldtype: "text",
    maxlength: 200,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "secret_access_key",
    label: "Secret Access Key",
    fieldtype: "text",
    maxlength: 250,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "bucket",
    label: "S3 Bucket",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "cluster",
    label: "AWS Container Cluster Name",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "subnets",
    label: "Subnet",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "securitygroups",
    label: "Security Groups",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
];

export const AZURE_customfields = [
  {
    name: "azureClientId",
    label: "Azure Client Id",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "azureClientSecret",
    label: "Azure Client Secret",
    fieldtype: "text",
    maxlength: 200,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "azureTenantId",
    label: "Azure Tenant Id",
    fieldtype: "text",
    maxlength: 200,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "azureSubscriptionId",
    label: "Azure Subscription Id",
    fieldtype: "text",
    maxlength: 50,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "resourceGroup",
    label: "Resource Group",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "storageAccountName",
    label: "Storage Account Name",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "containerRegistryName",
    label: "Container Registry Name",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
];
