import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "File Name",
    type: "file",
    maxLength: 200,
    required: true,
  },
  {
    name: "taskdocument2tasks",
    maxLength: 100,
    label: "Tasks",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/tasks`,
  },
];
const TaskDocumentFormContainer = ({
  propTaskDocument,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propTaskDocument?.objid || null,
    name: propTaskDocument?.name || null,
    url: propTaskDocument?.url || null,
    taskdocument2tasks: propTaskDocument?.taskdocument2tasks,
    customdata: propTaskDocument?.customdata || null,
    groupuserid: propTaskDocument?.x_groupuserid || user.x_groupuserid,
    createdbyid: propTaskDocument?.createdbyid || user.id,
    createdby: propTaskDocument?.createdby || user.fullname,
    createddate: propTaskDocument?.createddate
      ? formatDate(propTaskDocument.createddate)
      : today,
    modifiedby: propTaskDocument?.modifiedby || user.fullname,
    modifieddate: propTaskDocument?.modifieddate
      ? formatDate(propTaskDocument.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "TaskDocument", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="taskdocument"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default TaskDocumentFormContainer;
