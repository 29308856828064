import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import conf from "../config/config";
import { AuthContext } from "../context/AuthContext";
import "./ApproveTimesheet.css"; // Import CSS for styling

const ApproveTimesheet = ({ memberId }) => {
  const { user, logout } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setErrorMessage] = useState("");
  const [days, setDays] = useState(30); // Default value 30 days

  const fetchTimesheets = async (days) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/summary/getTaskTimesheetByUserId/${memberId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { dayCount: days },
        }
      );
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setErrorMessage(err.message);
      setLoading(false);
    }
  };

  const handleApprove = async (id) => {
    try {
      const response = await axios.post(
        `${conf.API_BASE_URL}/summary/approveTimesheet/${id}`,
        { approvedby: user.fullname },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        }
      );
      // Refresh data after approving
      fetchTimesheets(days);
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    fetchTimesheets(days);
  }, [memberId, user, days]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="timesheet-list">
      <h2>Timesheet</h2>
      {error && <div className="error-message">{error}</div>}
      <div className="search-container">
        <label htmlFor="days">Enter Number of Days:</label>
        <input
          type="number"
          id="days"
          value={days}
          onChange={(e) => setDays(e.target.value)}
          min="1"
        />
        <button onClick={() => fetchTimesheets(days)}>Search</button>
      </div>
      {Array.isArray(data) && data.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Week</th>
              <th>Estimated Time</th>
              <th>Time Worked</th>
              <th>Time Submitted</th>
              <th>Time Approved</th>
              <th>Time Remaining</th>
              <th>Submitted By</th>
              <th>Approved By</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              const isGreen =
                item.timeworked === item.timesubmitted &&
                item.timesubmitted === item.timeapproved;

              return (
                <tr
                  key={item.objid}
                  className={isGreen ? "green-row" : "yellow-row"}
                >
                  <td>
                    <Link to={`/timesheet/${item.objid}`}>{item.name}</Link>
                  </td>
                  <td>{item.weekstartdate}</td>
                  <td>{item.estimatedhr}</td>
                  <td>{item.timeworked}</td>
                  <td>{item.timesubmitted}</td>
                  <td>{item.timeapproved}</td>
                  <td>{item.timeremaining}</td>
                  <td>{item.submittedby}</td>
                  <td>
                    {item.isapproved === 0 ? (
                      <button onClick={() => handleApprove(item.objid)}>
                        Approve
                      </button>
                    ) : (
                      item.approvedby
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No Timesheet Submitted or Approved</div>
      )}
    </div>
  );
};

export default ApproveTimesheet;
