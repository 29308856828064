import mode from "./runenv";

console.log("mode.RUN_MODE=" + mode.RUN_MODE);

const config = {
  API_BASE_URL:
    mode.RUN_MODE === "dev"
      ? "http://localhost:3000/api"
      : "https://projextest.com/api",
  AUTH_URL:
    mode.RUN_MODE === "dev"
      ? "http://localhost:3000/auth"
      : "https://projextest.com/auth",
  USER_TYPE_REGISTRATION: "registration",
  USER_TYPE_LOGIN: "login",
  RECAPTCHA_SITE_KEY: "6LdTUYMqAAAAAC4R_DHwwega5ihO64WNSAB1So_o",
  RUN_MODE: mode.RUN_MODE,
};

export default config;
