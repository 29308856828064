import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format as yyyy-mm-dd HH:MM:SS
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 4000,
    required: true,
  },
  {
    name: "startdate",
    label: "Start Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "duedate",
    label: "Due Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "storypoint",
    label: "Story Point",
    type: "integer",
    maxLength: 16,
    required: true,
  },
  {
    name: "estimatedhr",
    label: "Estimated Hr",
    type: "float",
    maxLength: 7,
    required: true,
  },
  {
    name: "remaininghr",
    label: "Remaining Hr",
    type: "float",
    maxLength: 7,
    required: true,
  },
  {
    name: "taskstatuscode",
    label: "Status",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=TaskStatusCode`,
  },
  {
    name: "taskcode",
    label: "Task Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=TaskCode`,
  },
  {
    name: "subtask2tasks",
    maxLength: 100,
    label: "Tasks",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/tasks`,
  },
  {
    name: "subtask2assignee",
    maxLength: 100,
    label: "Assignee",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/assignee`,
  },
];
const SubTaskFormContainer = ({
  propSubTask,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propSubTask?.objid || null,
    name: propSubTask?.name || null,
    description: propSubTask?.description || null,
    startdate: propSubTask?.startdate || null,
    duedate: propSubTask?.duedate || null,
    storypoint: propSubTask?.storypoint || null,
    estimatedhr: propSubTask?.estimatedhr || null,
    remaininghr: propSubTask?.remaininghr || null,
    taskstatuscode: propSubTask?.taskstatuscode || null,
    taskcode: propSubTask?.taskcode || null,
    subtask2tasks: propSubTask?.subtask2tasks,
    subtask2assignee: propSubTask?.subtask2assignee,
    customdata: propSubTask?.customdata || null,
    groupuserid: propSubTask?.x_groupuserid || user.x_groupuserid,
    createdbyid: propSubTask?.createdbyid || user.id,
    createdby: propSubTask?.createdby || user.fullname,
    createddate: propSubTask?.createddate
      ? formatDate(propSubTask.createddate)
      : today,
    modifiedby: propSubTask?.modifiedby || user.fullname,
    modifieddate: propSubTask?.modifieddate
      ? formatDate(propSubTask.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "SubTask", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="subtask"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default SubTaskFormContainer;
