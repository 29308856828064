import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

function PrivateRoute({ children }) {
  const { user } = useContext(AuthContext);

  //console.log("User context:", user); // Debugging statement

  // Check if user is authenticated based on token or another property
  if (!user || !user.token) {
    console.log("User is not authenticated. Redirecting to login.");
    return <Navigate to="/login" />;
  }

  return children;
}

export default PrivateRoute;
