import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import "./SearchTestReport.css";
import { AuthContext } from "../../context/AuthContext";
import TestReportFormModal from "./TestReportFormModal";
import conf from "../../config/config";

const SearchTestReport = ({ setTestReportId }) => {
  const [name, setName] = useState("");
  const [reportdate, setReportDate] = useState("");
  const [testenv, setTestEnv] = useState("");
  const [testreports, setTestReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "desc" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSearch = async (page = 1) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/testreports/search`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { name, reportdate, testenv, page },
        },
      );
      setTestReports(response.data.testreports);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testreport:", error);
      }
    }
  };

  useEffect(() => {
    handleSearch(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditClick = (testreportId) => {
    navigate("/setup/edittestreport");
    setTestReportId(testreportId);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedTestReports = [...testreports].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCustomTestReportSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      if (response.data.error) {
        setErrorMessage("TestReport creation Failed!" + response.data.error);
      } else {
        setSuccessMessage("TestReport created successfully!");
      }
      setTestReports((prevTestReports) => {
        const updatedTestReports = [response.data, ...prevTestReports];
        console.log("Updated testreports:", updatedTestReports);
        return updatedTestReports;
      });
      closeModal();
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testreport:", error);
      }
    }
  };
  const getSignedUrl = async (key) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/docker/generate-signed-url`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { key },
        },
      );
      return response.data.url;
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching test result URL:", error);
      }
    }
  };

  const getAzureSignedUrl = async (key) => {
    try {
      const response = await axios.post(
        `${conf.API_BASE_URL}/docker/generate-Azure-signed-url`,
        { key },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      return response.data.signedUrl;
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching test result URL:", error);
      }
    }
  };

  const handleClick = async (urlKey) => {
    try {
      if (!urlKey) {
        setErrorMessage("No URL");
        return;
      }

      const urls = urlKey.split(";");

      for (const url of urls) {
        try {
          if (
            !url.includes("blob.core.windows.net") &&
            (url.startsWith("http:") || url.startsWith("https:"))
          ) {
            window.open(url, "_blank");
          } else if (
            url.includes("blob.core.windows.net") &&
            (url.startsWith("http:") || url.startsWith("https:"))
          ) {
            const blobUrl = await getAzureSignedUrl(url);
            window.open(blobUrl, "_blank");
          } else {
            const signedUrl = await getSignedUrl(url);
            window.open(signedUrl, "_blank");
          }
        } catch (error) {
          console.error("Error handling URL click:", error);
        }
      }
    } catch (error) {
      console.error("Error handling URL click:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return (
      date.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        day: "2-digit",
        month: "short",
        year: "numeric",
        timeZone: "UTC", // Ensure the date is interpreted as UTC
      }) + " UTC"
    ); // Append " UTC" to the formatted date
  };
  return (
    <div className="app-container">
      <div className="header">
        <h1>TestReport Search</h1>
      </div>
      <div className="content-container">
        <div className="main-content">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          <div className="search-form">
            {" "}
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />{" "}
            <DatePicker
              selected={reportdate}
              onChange={(date) => setReportDate(date)}
              placeholderText="Report Date"
              dateFormat="MM/dd/yyyy"
              className="input-field"
            />{" "}
            <input
              type="text"
              placeholder="Test Env"
              value={testenv}
              onChange={(e) => setTestEnv(e.target.value)}
            />
            <button onClick={() => handleSearch(1)}>Search</button>
          </div>
          <div className="results">
            <h2>Results</h2>
            <table>
              <thead>
                <tr>
                  {" "}
                  <th onClick={() => handleSort("name")}>Name</th>
                  <th onClick={() => handleSort("reportdate")}>Report Date</th>
                  <th onClick={() => handleSort("testenv")}>Test Env</th>
                </tr>
              </thead>
              <tbody>
                {sortedTestReports.map((testreport) => (
                  <tr key={testreport.objid}>
                    <td>
                      <Link to={`/testreport/${testreport.objid}`}>
                        {testreport.name || "Unnamed TestReport"}
                      </Link>
                    </td>
                    <td>{formatDate(testreport.reportdate)}</td>
                    <td>{testreport.testenv}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>{" "}
        <div className="sidebar">
          <ul>
            <li>
              <a href="#" onClick={openModal}>
                Create TestReport
              </a>
            </li>
            <li>
              <h4>TestReport Dependency</h4>
            </li>

            <li>
              <a href="/setup/testcycle">Search TestCycle</a>
            </li>
            <li>
              <h5>Instruction:</h5>
            </li>
            <li>
              Create or search dependencies before you proceed to create
              TestReport{" "}
            </li>
          </ul>
        </div>
      </div>
      <TestReportFormModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        testreport={null} // Pass the project data if needed
        onSubmit={handleCustomTestReportSubmit}
      />
    </div>
  );
};

export default SearchTestReport;
