import React from "react";

const SetupDetail = () => {
  return (
    <div>
      <h1>Setup Instruction:</h1>
      <p>
        Welcome to the setup page. Here you will find all the necessary details
        to get started with your new project.
      </p>
      <ul>
        <li>Step 1: Setup your product</li>
        <li>Step 2: Setup Project & Release.</li>
        <li>
          Step 3: Setup AWS cloud for S3 Bucket and to run Docker container. You
          may need to provide{" "}
          <a
            href="https://github.com/srimanta1968/testresult/blob/main/s3BucketPolicy.json"
            target="_blank"
          >
            proper permission
          </a>
          for s3 bucket to access your reports from Projexlight. You may also
          setup Microsoft Azure cloud for Docker Container using Cloud Config
          link in setup menu.
        </li>
        <li>
          Step 4: Use{" "}
          <a
            href="https://github.com/srimanta1968/testresult/blob/main/Dockerfile"
            target="_blank"
          >
            this Docker file
          </a>
          to create docker image to run the demo in AWS. For Azure, use the
          docker file given in Azure section.
        </li>
        <li>
          Step 5: If you want to publish your test results you should customize
          <a href="https://github.com/srimanta1968/testresult" target="_blank">
            this script
          </a>
          as you need.
        </li>
        <li>
          Step 6: Please refer this sample project and run a demo using it
          <a
            href="https://github.com/srimanta1968/playwright--end2end.git"
            target="_blank"
          >
            Sample Project
          </a>
          as you need.
        </li>
      </ul>
      <h3>Watch Videos</h3>
      <p>
        Learn Project Management using Projexlight{" "}
        <a href="https://youtu.be/v7j7NGaIW2s" target="_blank">
          Click Here to Watch
        </a>
      </p>
      <p>
        Learn Test Management & Execution using Projexlight for AWS Docker
        Container{" "}
        <a href="https://youtu.be/0TeVlhcv7L0" target="_blank">
          Click Here to Watch
        </a>
      </p>
      <p>
        Learn Test Management & Execution using Projexlight using Azure Docker
        Container{" "}
        <a href="https://youtu.be/93v3_frdwQY" target="_blank">
          Click Here to Watch
        </a>
      </p>
      <h3>AWS Account:</h3>
      <p>
        Please use this{" "}
        <a
          href="https://github.com/srimanta1968/testresult/blob/main/SecurityPolicy.json"
          target="_blank"
        >
          security policy
        </a>
        for AWS account which will allow to run the container using farget
        container service and store test results and documents in S3 bucket and
        publish to ProjectX account. Your test results will not be visible to
        anybody except you unless you add any project member to your project
        team and give access using member role.
      </p>
      <p>
        Please create proper role and security policy as per your organization.
        Using CloudConfig link provide accessKeyId, secretAccessKey, bucket name
        and region. Your AWS account details will not be displayed either to you
        or anybody once this record is saved. If you need to change then delete
        the existing record and create again new.
      </p>
      <h3>Azure Setup:</h3>
      <p>
        To setup Azure, click on Setup menu and then click Cloud Config. Select
        Cloud provider as Azure and enter these input values Azure Client Id,
        Azure Client Secret, Azure Tenant Id,Azure Subscription Id, Resource
        Group, Storage Account Name and Container Registry Name and click Save.
      </p>
      <p>
        Please
        <a
          href="https://github.com/srimanta1968/testresult/blob/main/AzureRole.txt"
          target="_blank"
        >
          follow this link
        </a>
        and you may use Azure command to setup your account in Azure Cloud or
        ask your IT team to setup for you.
      </p>
      <p>
        To create a docker image in Azure, use{" "}
        <a
          href="https://github.com/srimanta1968/testresult/blob/main/Azure_Dockerfile"
          target="_blank"
        >
          this docker file.
        </a>
      </p>
      <h3>Project Team:</h3>{" "}
      <p>
        {" "}
        To set up your project team, create a project board, add members with
        roles, and specify the start and end dates during which the project will
        be visible to the members. Add sprints with start and end dates, create
        tasks for each feature, and assign them to members by selecting a
        member. Tasks with due dates within the sprint start and end dates will
        be visible within that sprint.{" "}
      </p>
      <p>
        {" "}
        To work on your project the project member should click the Switch
        button on the project Dashboard to get access to your project.
      </p>
      <h3>Custom Fields:</h3>
      <p>
        We allow custom fields for certain object. Please look at custom filed
        link and see if you need to add any custom field for any of these
        objects which are in the dropdown.
      </p>
    </div>
  );
};

export default SetupDetail;
