import React from "react";
import MyProjects from "../common/MyProjects";
import AssignedProjects from "../common/AssignedProjects";
import MyTestSuite from "../common/MyTestSuite";
import "./Home.css"; // Import the CSS file

function Home() {
  return (
    <div className="home-container">
      <h2>Dashboard</h2>
      <MyProjects />
      <AssignedProjects />
      <MyTestSuite />
    </div>
  );
}

export default Home;
