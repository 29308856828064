import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import conf from "../config/config";
import "./AssignedProjects.css";

function AssignedProjects() {
  const { user, switchProject } = useAuth();
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const [currentPageAssigned, setCurrentPageAssigned] = useState(1);
  const [totalPagesAssigned, setTotalPagesAssigned] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchAssignedProjects = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/dashboard/getAssignedProject`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              my_groupuser_id: `${user.my_groupuseid}`,
              my_poolindex: `${user.my_poolindex}`,
            },
            params: {
              assignee: user.id, // Assuming user.id is the member2assignee value
              limit: itemsPerPage,
              offset: (currentPageAssigned - 1) * itemsPerPage,
            },
          }
        );
        setAssignedProjects(response.data.projects || []);
        setTotalPagesAssigned(
          Math.ceil((response.data.total || 0) / itemsPerPage)
        );
      } catch (error) {
        console.error("Error fetching assigned projects:", error);
      }
    };

    fetchAssignedProjects();
  }, [user, currentPageAssigned]);

  const handleProjectSwitch = (project) => {
    switchProject(project);
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedProjects = (projects) => {
    return [...projects].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  return (
    <div className="assigned-projects-container">
      <h2>Assigned Projects</h2>
      {assignedProjects.length === 0 ? (
        <p>No projects assigned</p>
      ) : (
        <>
          <table className="assigned-projects-table">
            <thead>
              <tr>
                <th onClick={() => requestSort("name")}>Name</th>
                <th onClick={() => requestSort("startdate")}>Start Date</th>
                <th onClick={() => requestSort("enddate")}>End Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortedProjects(assignedProjects).map((project) => (
                <tr key={project.objid}>
                  <td>{project.name}</td>
                  <td>{new Date(project.startdate).toLocaleDateString()}</td>
                  <td>{new Date(project.enddate).toLocaleDateString()}</td>
                  <td>
                    <button onClick={() => handleProjectSwitch(project)}>
                      Switch
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: totalPagesAssigned }, (_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPageAssigned(index + 1)}
                disabled={currentPageAssigned === index + 1}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AssignedProjects;
