import React, { useState, useContext } from "react";
import axios from "axios";
import conf from "../config/config";
import { AuthContext } from "../context/AuthContext";
import "./GenerateAuthToken.css"; // Import CSS for styling

const GenerateAuthToken = () => {
  const { user, logout } = useContext(AuthContext);
  const [days, setDays] = useState(90); // Default value 90 days
  const [tokenData, setTokenData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setErrorMessage] = useState("");

  const generateToken = async () => {
    setLoading(true);
    setErrorMessage("");

    const data = {
      logintype: "apitoken",
      userid: user.id,
      days: days,
    };

    try {
      const response = await axios.post(
        `${conf.AUTH_URL}/create_api_token`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
            "Content-Type": "application/json",
          },
        }
      );
      setTokenData(response.data);
      setLoading(false);
    } catch (err) {
      setErrorMessage(err.message);
      setLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Copied to clipboard: " + text);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="generate-auth-token">
      <h2>Generate API Auth Token</h2>
      <div className="input-container">
        <label htmlFor="days">Expiry Days:</label>
        <input
          type="number"
          id="days"
          value={days}
          onChange={(e) => setDays(e.target.value)}
          min="1"
        />
        <button onClick={generateToken} disabled={loading}>
          {loading ? "Generating..." : "Generate"}
        </button>
      </div>
      {error && <div className="error-message">{error}</div>}
      {tokenData && (
        <table className="token-table">
          <thead>
            <tr>
              <th>Header</th>
              <th>Action</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bearer Token</td>
              <td>
                <button onClick={() => copyToClipboard(tokenData.token)}>
                  Copy
                </button>
              </td>
              <td>{tokenData.token}</td>
            </tr>
            <tr>
              <td>x_groupuserid</td>
              <td>
                <button
                  onClick={() => copyToClipboard(tokenData.x_groupuserid)}
                >
                  Copy
                </button>
              </td>
              <td>{tokenData.x_groupuserid}</td>
            </tr>
            <tr>
              <td>x_poolindex</td>
              <td>
                <button onClick={() => copyToClipboard(tokenData.x_poolindex)}>
                  Copy
                </button>
              </td>
              <td>{tokenData.x_poolindex}</td>
            </tr>
            <tr>
              <td>x_account_id</td>
              <td>
                <button onClick={() => copyToClipboard(tokenData.x_account_id)}>
                  Copy
                </button>
              </td>
              <td>{tokenData.x_account_id}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default GenerateAuthToken;
