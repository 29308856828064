export const handleEditClick = (field, value, setEditField, setEditValue) => {
  setEditField(field);
  setEditValue(value);
};

export const handleChange = (e, setEditValue) => {
  console.log("handleChange value=" + e.target.value);
  setEditValue(e.target.value);
};
export const handleSaveClick = async (
  field,
  editValue,
  modifiedData,
  setModifiedData,
  handleSave,
  setEditField
) => {
  const fieldParts = field.split(".");
  const fieldKey = fieldParts.length > 1 ? fieldParts[1] : fieldParts[0];

  console.log("field=" + field + " field key=" + fieldKey);

  const isCustomField = fieldParts[0] === "customdata";
  console.log("Updated data to be saved in handleSaveClick:", modifiedData);

  const updatedData = isCustomField
    ? {
        ...modifiedData,
        customdata: {
          ...modifiedData.customdata,
          [fieldKey]: editValue,
        },
      }
    : {
        ...modifiedData,
        [fieldKey]: editValue,
      };

  console.log("Updated data to be saved in handleSaveClick 2:", updatedData);

  try {
    await handleSave(updatedData);
    setModifiedData(updatedData);
    setEditField(null);
  } catch (error) {
    console.error("Error saving data:", error);
  }
};

export const handleSaveClickReadOnly = async (
  field,
  editValue,
  modifiedData,
  setModifiedData,
  handleSave,
  setEditField
) => {
  const fieldParts = field.split(".");
  const fieldKey = fieldParts.length > 1 ? fieldParts[1] : fieldParts[0];

  console.log("field=" + field + " field key=" + fieldKey);

  const isCustomField = fieldParts[0] === "customdata";
  console.log("Updated data to be saved in handleSaveClick:", modifiedData);

  const updatedData = isCustomField
    ? {
        ...modifiedData,
        customdata: {
          ...modifiedData.customdata,
          [fieldKey]: editValue,
        },
      }
    : {
        ...modifiedData,
        [fieldKey]: editValue,
      };

  console.log("Updated data to be saved in handleSaveClick 2:", updatedData);

  try {
    //await handleSave(updatedData);
    setModifiedData(updatedData);
    setEditField(null);
  } catch (error) {
    console.error("Error saving data:", error);
  }
};

export const handleComboChange = (
  fieldKey,
  value,
  setModifiedData,
  options,
  setInitialNames,
  callback,
  fieldAutopopulate = null
) => {
  console.log(
    `handleComboChange called with fieldKey: ${fieldKey}, value: ${value}`
  );
  const selectedOption = Array.isArray(options)
    ? options.find((option) => option.objid === value)
    : null;

  setModifiedData((prevData) => {
    let updatedData = { ...prevData, [fieldKey]: value };

    // Correctly update the autopopulated field without duplicating
    if (fieldAutopopulate) {
      const { [fieldAutopopulate]: _, ...rest } = updatedData;
      updatedData = {
        ...rest,
        [fieldAutopopulate]: selectedOption ? selectedOption.name : "",
      };
    }

    console.log(`Updated modifiedData in handleComboChange:`, updatedData);
    if (callback) callback(updatedData);
    return updatedData;
  });

  if (selectedOption) {
    setInitialNames((prevNames) => ({
      ...prevNames,
      [fieldKey]: selectedOption.name,
    }));

    if (fieldAutopopulate) {
      setModifiedData((prevData) => ({
        ...prevData,
        [fieldAutopopulate]: selectedOption.name,
      }));
    }
  }
};

export const handleSearchChange = (field, e, setSearchTerm, setEditField) => {
  setSearchTerm(e.target.value);
  setEditField(field);
};

export const handleCheckboxChange = (
  field,
  checked,
  modifiedData,
  setModifiedData,
  handleSave
) => {
  const updatedData = {
    ...modifiedData,
    [field]: checked.toString(),
  };
  setModifiedData(updatedData);
  handleSave(updatedData);
};

export const handleFileChange = (key, event, setModifiedData) => {
  const file = event.target.files[0];
  if (file) {
    const formData = new FormData();
    formData.append(key, file);

    fetch("/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully:", data);
        setModifiedData((prevState) => ({
          ...prevState,
          [key]: data.filePath,
        }));
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  }
};

export const renderUpto300BasedOnMode = (
  field1,
  field2,
  editField,
  editValue,
  modifiedData,
  handleSaveClick,
  handleEditClick,
  handleChange,
  handleComboChange,
  handleSearchChange,
  handleFileChange,
  handleSave,
  handleCheckboxChange,
  options,
  searchTerm,
  initialNames,
  setEditField,
  setEditValue,
  setSearchTerm,
  setModifiedData,
  renderField,
  renderFieldReadOnly,
  setInitialNames,
  mode = "edit"
) => {
  if (mode === "readOnly") {
    return renderUpto300ReadOnly(
      field1,
      field2,
      modifiedData,
      initialNames,
      options,
      renderFieldReadOnly
    );
  }
  return renderUpto300(
    field1,
    field2,
    editField,
    editValue,
    modifiedData,
    handleSaveClick,
    handleEditClick,
    handleChange,
    handleComboChange,
    handleSearchChange,
    handleFileChange,
    handleSave,
    handleCheckboxChange,
    options,
    searchTerm,
    initialNames,
    setEditField,
    setEditValue,
    setSearchTerm,
    setModifiedData,
    renderField,
    setInitialNames
  );
};

export const renderUpto300ReadOnly = (
  field1,
  field2,
  modifiedData,
  initialNames = {}, // Default to an empty object if initialNames is null or undefined
  options = [], // Default to an empty array if options are null or undefined
  renderFieldReadOnly // Accept renderFieldReadOnly as a parameter
) => (
  <tr key={field1?.key || field2?.key}>
    {field1 &&
      renderFieldReadOnly(
        field1,
        modifiedData,
        initialNames, // Pass initialNames here
        options // Pass options here
      )}
    {field2 &&
      renderFieldReadOnly(
        field2,
        modifiedData,
        initialNames, // Pass initialNames here
        options // Pass options here
      )}
  </tr>
);

export const renderUpto300 = (
  field1,
  field2,
  editField,
  editValue,
  modifiedData,
  handleSaveClick,
  handleEditClick,
  handleChange,
  handleComboChange,
  handleSearchChange,
  handleFileChange,
  handleSave,
  handleCheckboxChange,
  options,
  searchTerm,
  initialNames,
  setEditField,
  setEditValue,
  setSearchTerm,
  setModifiedData,
  renderField, // Accept renderField as a parameter
  setInitialNames // Add this line
) => (
  <tr key={field1?.key || field2?.key}>
    {field1 &&
      renderField(
        field1,
        editField,
        editValue,
        modifiedData,
        handleSaveClick,
        handleEditClick,
        handleChange,
        handleComboChange,
        handleSearchChange,
        handleFileChange,
        handleSave,
        handleCheckboxChange,
        options,
        searchTerm,
        initialNames,
        setEditField,
        setEditValue,
        setSearchTerm,
        setModifiedData,
        setInitialNames // Add this line
      )}
    {field2 &&
      renderField(
        field2,
        editField,
        editValue,
        modifiedData,
        handleSaveClick,
        handleEditClick,
        handleChange,
        handleComboChange,
        handleSearchChange,
        handleFileChange,
        handleSave,
        handleCheckboxChange,
        options,
        searchTerm,
        initialNames,
        setEditField,
        setEditValue,
        setSearchTerm,
        setModifiedData,
        setInitialNames // Add this line
      )}
  </tr>
);

export const render300Plus = (
  field,
  editField,
  editValue,
  modifiedData,
  handleSaveClick,
  handleEditClick,
  handleChange,
  setEditField,
  setEditValue,
  setModifiedData,
  handleSave,
  mode = "edit" // Default to "edit" if mode is null
) => {
  if (field.fieldtype && field.fieldtype === "hidden") {
    //console.log(" calling render300Plus");
    return (
      <tr key={field.key}>
        <td colSpan="2">
          <input
            type="text"
            value={editValue}
            readOnly={field.fieldtype === "readonly" ? true : false}
            style={{ display: "none" }}
            name={field.key}
          />
        </td>
      </tr>
    );
  }

  return (
    <tr key={field.key}>
      <td colSpan="2">
        <strong>{field.label}</strong>
        {editField === field.key ? (
          <>
            <textarea
              value={editValue}
              onChange={(e) => handleChange(e, setEditValue)}
              rows="4"
              cols="50"
              style={{ whiteSpace: "pre-wrap" }} // Maintain indentation and new lines
              name={field.key}
            />
            <button
              onClick={() =>
                handleSaveClick(
                  field.key,
                  editValue,
                  modifiedData,
                  setModifiedData,
                  handleSave,
                  setEditField
                )
              }
            >
              ✔️
            </button>
          </>
        ) : (
          <>
            <div style={{ whiteSpace: "pre-wrap" }}>
              {modifiedData[field.key]}
            </div>
            {mode !== "readOnly" && (
              <button
                onClick={() =>
                  handleEditClick(
                    field.key,
                    modifiedData[field.key],
                    setEditField,
                    setEditValue
                  )
                }
              >
                ✏️
              </button>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

export const renderField = (
  field,
  editField,
  editValue,
  modifiedData,
  handleSaveClick,
  handleEditClick,
  handleChange,
  handleComboChange,
  handleSearchChange,
  handleFileChange,
  handleSave,
  handleCheckboxChange,
  options,
  searchTerm,
  initialNames,
  setEditField,
  setEditValue,
  setSearchTerm,
  setModifiedData,
  setInitialNames // Ensure this line is included
) => {
  const getFieldValue = (fieldKey) => {
    const keys = fieldKey.split(".");
    return keys.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      modifiedData
    );
  };

  let displayValue = getFieldValue(field.key);
  if (
    field.type === "date" ||
    field.type === "datetime" ||
    field.type === "time"
  ) {
    displayValue = formatDate(displayValue);
  }

  const fieldValue = displayValue || getFieldValue(field.key);

  const renderFieldValue = (value) => {
    if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return value;
  };

  return (
    <td key={field.key}>
      {field.fieldtype !== "hidden" && <strong>{field.label}</strong>}
      <div className="field-value">
        {editField === field.key ? (
          <>
            {field.type === "combo" &&
              renderComboField(
                field,
                editValue,
                searchTerm,
                options,
                handleSearchChange,
                handleChange,
                handleComboChange,
                handleSaveClick,
                setSearchTerm,
                setEditField,
                setEditValue,
                setModifiedData,
                modifiedData,
                handleSave,
                setInitialNames
              )}
            {field.type === "checkbox" &&
              renderCheckboxField(
                field,
                fieldValue,
                handleCheckboxChange,
                modifiedData,
                setModifiedData,
                handleSave
              )}
            {field.type === "select" &&
              renderSelectField(
                field,
                editValue,
                handleChange,
                handleSaveClick,
                setEditValue,
                modifiedData,
                setModifiedData,
                handleSave,
                setEditField
              )}
            {(field.type === "date" ||
              field.type === "datetime" ||
              field.type === "time") &&
              renderDateField(
                field,
                editValue,
                handleChange,
                handleSaveClick,
                setEditValue,
                modifiedData,
                setModifiedData,
                handleSave,
                setEditField
              )}
            {field.type === "file" &&
              renderFileField(
                field,
                handleFileChange,
                handleSaveClick,
                modifiedData,
                setModifiedData,
                handleSave,
                setEditField
              )}
            {field.type === "password" &&
              renderPasswordField(
                field,
                editValue,
                handleChange,
                handleSaveClick,
                setEditValue,
                modifiedData,
                setModifiedData,
                handleSave,
                setEditField
              )}
            {field.type === "text" &&
              (field.fieldtype === "hidden" ||
                field.fieldtype === "readonly") &&
              renderTextFieldReadOnly(
                field,
                editValue,
                handleChange,
                handleSaveClick,
                setEditValue,
                modifiedData,
                setModifiedData,
                handleSave,
                setEditField
              )}
            {field.type === "text" &&
              (field.fieldtype !== "hidden" ||
                field.fieldtype !== "readonly") &&
              renderTextField(
                field,
                editValue,
                handleChange,
                handleSaveClick,
                setEditValue,
                modifiedData,
                setModifiedData,
                handleSave,
                setEditField
              )}
            {(field.type === "number" ||
              field.type === "integer" ||
              field.type === "float" ||
              field.type === "decimal" ||
              field.type === "int") &&
              renderNumberField(
                field,
                editValue,
                handleChange,
                handleSaveClick,
                setEditValue,
                modifiedData,
                setModifiedData,
                handleSave,
                setEditField
              )}
          </>
        ) : (
          <>
            {field.type === "combo" ? (
              field.link ? (
                <a href={`${field.link}/${fieldValue}`}>
                  {initialNames[field.key] || "Loading..."}
                </a>
              ) : (
                initialNames[field.key] || "Loading..."
              )
            ) : field.type === "checkbox" ? (
              <input
                type="checkbox"
                checked={fieldValue === "true"}
                onChange={(e) => {
                  handleCheckboxChange(
                    field.key,
                    e.target.checked,
                    modifiedData,
                    setModifiedData,
                    handleSave
                  );
                }}
                name={field.key}
              />
            ) : field.fieldtype === "hidden" && fieldValue ? (
              <div></div>
            ) : field.type === "password" && fieldValue ? (
              <div>{`***${fieldValue.slice(-2)}`}</div>
            ) : (
              <div>{renderFieldValue(fieldValue)}</div>
            )}
            {field.editable !== false &&
              field.type !== "checkbox" &&
              !field.fieldtype && (
                <button
                  onClick={() =>
                    handleEditClick(
                      field.key,
                      fieldValue,
                      setEditField,
                      setEditValue
                    )
                  }
                >
                  ✏️
                </button>
              )}
          </>
        )}
      </div>
    </td>
  );
};

export const renderFieldReadOnly = (
  field,
  modifiedData,
  initialNames = {}, // Default to an empty object if initialNames is null or undefined
  options = [] // Default to an empty array if options are null or undefined
) => {
  const getFieldValue = (fieldKey) => {
    const keys = fieldKey.split(".");
    return keys.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      modifiedData
    );
  };

  let displayValue = getFieldValue(field.key);
  if (
    field.type === "date" ||
    field.type === "datetime" ||
    field.type === "time"
  ) {
    displayValue = formatDate(displayValue);
  }

  const fieldValue = displayValue || getFieldValue(field.key);

  const renderFieldValue = (value) => {
    if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return value;
  };

  if (field.type === "combo") {
    const displayValue = initialNames[field.key] || fieldValue;
    return (
      <td key={field.key}>
        <strong>{field.label}</strong>
        <span>{displayValue}</span>
      </td>
    );
  }

  if (field.type === "select") {
    const selectedOption = Array.isArray(options)
      ? options.find((option) => option.value === fieldValue)
      : null;
    const displayValue = selectedOption ? selectedOption.text : fieldValue;
    return (
      <td key={field.key}>
        <strong>{field.label}</strong>
        <span>{displayValue}</span>
      </td>
    );
  }

  return (
    <td key={field.key}>
      <strong>{field.label}</strong>
      <span>{renderFieldValue(fieldValue)}</span>
    </td>
  );
};

const renderNumberField = (
  field,
  editValue,
  handleChange,
  handleSaveClick,
  setEditValue,
  modifiedData,
  setModifiedData,
  handleSave,
  setEditField
) => (
  <>
    <input
      type="number"
      value={editValue !== null ? editValue : 0}
      onChange={(e) => {
        console.log(`handleChange called with value: ${e.target.value}`);
        handleChange(e, setEditValue);
      }}
      name={field.key}
    />
    <button
      onClick={() =>
        handleSaveClick(
          field.key,
          editValue !== null ? editValue : 0,
          modifiedData,
          setModifiedData,
          handleSave,
          setEditField
        )
      }
    >
      ✔️
    </button>
  </>
);

const renderComboField = (
  field,
  editValue,
  searchTerm,
  options,
  handleSearchChange,
  handleChange,
  handleComboChange,
  handleSaveClick,
  setSearchTerm,
  setEditField,
  setEditValue,
  setModifiedData,
  modifiedData,
  handleSave,
  setInitialNames
) => {
  // Normalize options to always be an array
  const normalizedOptions = Array.isArray(options) ? options : [options];

  const onInputChange = (e) => {
    const newValue = e.target.value;
    handleChange(e, setEditValue);
    console.log(
      `Calling handleComboChange with fieldKey: ${field.key}, value: ${newValue}`
    );
    handleComboChange(
      field.key,
      newValue,
      setModifiedData,
      normalizedOptions,
      setInitialNames,
      null,
      field.autopopulate
    );
  };

  return (
    <>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => {
          console.log(
            `handleSearchChange called with fieldKey: ${field.key}, value: ${e.target.value}`
          );
          handleSearchChange(field.key, e, setSearchTerm, setEditField);
        }}
        placeholder={`Search ${field.label}`}
      />
      <select value={editValue} onChange={onInputChange}>
        {normalizedOptions.length > 0 ? (
          normalizedOptions.map((option) => (
            <option key={option.objid} value={option.objid}>
              {option.name}
            </option>
          ))
        ) : (
          <option value="">No options found</option>
        )}
      </select>
      <button
        onClick={(e) => {
          e.stopPropagation(); // Prevent event propagation
          const selectedValue =
            normalizedOptions.length === 1
              ? normalizedOptions[0].objid
              : editValue;
          handleComboChange(
            field.key,
            selectedValue,
            setModifiedData,
            normalizedOptions,
            setInitialNames,
            (updatedData) => {
              console.log("Saving with selected value:", updatedData);
              handleSaveClick(
                field.key,
                selectedValue,
                updatedData,
                setModifiedData,
                handleSave,
                setEditField
              );
            },
            field.autopopulate
          );
        }}
        style={{ marginLeft: "20px" }} // Add left margin to the button
      >
        ✔️
      </button>
    </>
  );
};

const renderCheckboxField = (
  field,
  fieldValue,
  handleCheckboxChange,
  modifiedData,
  setModifiedData,
  handleSave
) => (
  <input
    type="checkbox"
    checked={fieldValue === "true"}
    onChange={(e) =>
      handleCheckboxChange(
        field.key,
        e.target.checked,
        modifiedData,
        setModifiedData,
        handleSave
      )
    }
    name={field.key}
  />
);

const renderSelectField = (
  field,
  editValue,
  handleChange,
  handleSaveClick,
  setEditValue,
  modifiedData,
  setModifiedData,
  handleSave,
  setEditField
) => (
  <>
    <select
      value={editValue}
      onChange={(e) => {
        console.log(`handleChange called with value: ${e.target.value}`);
        handleChange(e, setEditValue);
      }}
      name={field.key}
    >
      {Object.entries(field.options).map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
    <button
      onClick={() =>
        handleSaveClick(
          field.key,
          editValue,
          modifiedData,
          setModifiedData,
          handleSave,
          setEditField
        )
      }
    >
      ✔️
    </button>
  </>
);

const renderDateField = (
  field,
  editValue,
  handleChange,
  handleSaveClick,
  setEditValue,
  modifiedData,
  setModifiedData,
  handleSave,
  setEditField
) => (
  <>
    <input
      type={field.type}
      value={editValue}
      onChange={(e) => {
        console.log(`handleChange called with value: ${e.target.value}`);
        handleChange(e, setEditValue);
      }}
      name={field.key}
    />
    <button
      onClick={() =>
        handleSaveClick(
          field.key,
          editValue,
          modifiedData,
          setModifiedData,
          handleSave,
          setEditField
        )
      }
      style={{ marginLeft: "20px" }} // Add left margin to the button
    >
      ✔️
    </button>
  </>
);

const renderFileField = (
  field,
  handleFileChange,
  handleSaveClick,
  modifiedData,
  setModifiedData,
  handleSave,
  setEditField
) => (
  <>
    <input
      type="file"
      onChange={(e) => {
        console.log(`handleFileChange called with fieldKey: ${field.key}`);
        handleFileChange(field.key, e, setModifiedData);
      }}
      name={field.key}
    />
    <button
      onClick={() =>
        handleSaveClick(
          field.key,
          null,
          modifiedData,
          setModifiedData,
          handleSave,
          setEditField
        )
      }
    >
      ✔️
    </button>
  </>
);

const renderPasswordField = (
  field,
  editValue,
  handleChange,
  handleSaveClick,
  setEditValue,
  modifiedData,
  setModifiedData,
  handleSave,
  setEditField
) => (
  <>
    <input
      type="password"
      value={editValue}
      onChange={(e) => {
        console.log(`handleChange called with value: ${e.target.value}`);
        handleChange(e, setEditValue);
      }}
      name={field.key}
    />
    <button
      onClick={() =>
        handleSaveClick(
          field.key,
          editValue,
          modifiedData,
          setModifiedData,
          handleSave,
          setEditField
        )
      }
    >
      ✔️
    </button>
  </>
);

const renderTextField = (
  field,
  editValue,
  handleChange,
  handleSaveClick,
  setEditValue,
  modifiedData,
  setModifiedData,
  handleSave,
  setEditField
) => (
  <>
    <input
      type="text"
      value={editValue}
      onChange={(e) => {
        // console.log(`handleChange called with value: ${e.target.value}`);
        handleChange(e, setEditValue);
      }}
      name={field.key}
    />
    <button
      onClick={() =>
        handleSaveClick(
          field.key,
          editValue,
          modifiedData,
          setModifiedData,
          handleSave,
          setEditField
        )
      }
    >
      ✔️
    </button>
  </>
);

export const renderTextFieldReadOnly = (
  field,
  editValue,
  handleChange,
  handleSaveClick,
  setEditValue,
  modifiedData,
  setModifiedData,
  handleSave,
  setEditField
) => {
  if (field.fieldtype === "hidden") {
    return (
      <>
        <input
          type="text"
          value={editValue}
          readOnly={field.fieldtype === "readonly" ? true : false}
          style={{ display: "none" }}
          name={field.key}
        />
      </>
    );
  } else {
    return (
      <>
        <input
          type="text"
          value={editValue}
          readOnly={field.fieldtype === "readonly" ? true : false}
          name={field.key}
        />
      </>
    );
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return (
    date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      day: "2-digit",
      month: "short",
      year: "numeric",
      timeZone: "UTC", // Ensure the date is interpreted as UTC
    }) + " UTC"
  ); // Append " UTC" to the formatted date
};
