import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import "./SearchTestFailure.css";
import { AuthContext } from "../../context/AuthContext";
import TestFailureFormModal from "./TestFailureFormModal";
import conf from "../../config/config";

const SearchTestFailure = ({ setTestFailureId }) => {
  const [name, setName] = useState("");
  const [scenario, setScenario] = useState("");
  const [isdefect, setIsDefect] = useState("");
  const [testfailures, setTestFailures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "desc" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSearch = async (page = 1) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/testfailures/search`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { name, scenario, isdefect, page },
        },
      );
      setTestFailures(response.data.testfailures);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testfailure:", error);
      }
    }
  };

  useEffect(() => {
    handleSearch(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditClick = (testfailureId) => {
    navigate("/setup/edittestfailure");
    setTestFailureId(testfailureId);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedTestFailures = [...testfailures].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCustomTestFailureSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      if (response.data.error) {
        setErrorMessage("TestFailure creation Failed!" + response.data.error);
      } else {
        setSuccessMessage("TestFailure created successfully!");
      }
      setTestFailures((prevTestFailures) => {
        const updatedTestFailures = [response.data, ...prevTestFailures];
        console.log("Updated testfailures:", updatedTestFailures);
        return updatedTestFailures;
      });
      closeModal();
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testfailure:", error);
      }
    }
  };
  const getSignedUrl = async (key) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/docker/generate-signed-url`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { key },
        },
      );
      return response.data.url;
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching test result URL:", error);
      }
    }
  };

  const getAzureSignedUrl = async (key) => {
    try {
      const response = await axios.post(
        `${conf.API_BASE_URL}/docker/generate-Azure-signed-url`,
        { key },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      return response.data.signedUrl;
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching test result URL:", error);
      }
    }
  };

  const handleClick = async (urlKey) => {
    try {
      if (!urlKey) {
        setErrorMessage("No URL");
        return;
      }

      const urls = urlKey.split(";");

      for (const url of urls) {
        try {
          if (
            !url.includes("blob.core.windows.net") &&
            (url.startsWith("http:") || url.startsWith("https:"))
          ) {
            window.open(url, "_blank");
          } else if (
            url.includes("blob.core.windows.net") &&
            (url.startsWith("http:") || url.startsWith("https:"))
          ) {
            const blobUrl = await getAzureSignedUrl(url);
            window.open(blobUrl, "_blank");
          } else {
            const signedUrl = await getSignedUrl(url);
            window.open(signedUrl, "_blank");
          }
        } catch (error) {
          console.error("Error handling URL click:", error);
        }
      }
    } catch (error) {
      console.error("Error handling URL click:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return (
      date.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        day: "2-digit",
        month: "short",
        year: "numeric",
        timeZone: "UTC", // Ensure the date is interpreted as UTC
      }) + " UTC"
    ); // Append " UTC" to the formatted date
  };
  return (
    <div className="app-container">
      <div className="header">
        <h1>Test Failure Search</h1>
      </div>
      <div className="content-container">
        <div className="main-content">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          <div className="search-form">
            {" "}
            <input
              type="text"
              placeholder="Test Step"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />{" "}
            <input
              type="text"
              placeholder="Scenario / Test Name"
              value={scenario}
              onChange={(e) => setScenario(e.target.value)}
            />{" "}
            <input
              type="text"
              placeholder="Is Defect"
              value={isdefect}
              onChange={(e) => setIsDefect(e.target.value)}
              className="input-field"
            />
            <button onClick={() => handleSearch(1)}>Search</button>
          </div>
          <div className="results">
            <h2>Results</h2>
            <table>
              <thead>
                <tr>
                  {" "}
                  <th onClick={() => handleSort("name")}>Test Step</th>
                  <th onClick={() => handleSort("scenario")}>
                    Scenario / Test Name
                  </th>
                  <th onClick={() => handleSort("logurl")}>Log Url</th>
                  <th onClick={() => handleSort("reporturl")}>ReportURL</th>
                  <th onClick={() => handleSort("isdefect")}>Is Defect</th>
                </tr>
              </thead>
              <tbody>
                {sortedTestFailures.map((testfailure) => (
                  <tr key={testfailure.objid}>
                    <td>
                      <Link to={`/testfailure/${testfailure.objid}`}>
                        {testfailure.name || "Unnamed TestFailure"}
                      </Link>
                    </td>
                    <td>{testfailure.scenario}</td>
                    <td>
                      <button onClick={() => handleClick(testfailure.logurl)}>
                        Log Url
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => handleClick(testfailure.reporturl)}
                      >
                        ReportURL
                      </button>
                    </td>
                    <td>{testfailure.isdefect}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>{" "}
        <div className="sidebar">
          <ul>
            <li>
              <a href="#" onClick={openModal}>
                Create TestFailure
              </a>
            </li>
            <li>
              <h4>TestFailure Dependency</h4>
            </li>

            <li>
              <a href="/setup/testreport">Search TestReport</a>
            </li>
            <li>
              <a href="/setup/testsuite">Search TestSuite</a>
            </li>
            <li>
              <a href="/setup/feature">Search Feature</a>
            </li>
            <li>
              <a href="/setup/scenario">Search Scenario</a>
            </li>
            <li>
              <a href="/setup/assignee">Search Assignee</a>
            </li>
            <li>
              <h5>Instruction:</h5>
            </li>
            <li>
              Create or search dependencies before you proceed to create
              TestFailure{" "}
            </li>
          </ul>
        </div>
      </div>
      <TestFailureFormModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        testfailure={null} // Pass the project data if needed
        onSubmit={handleCustomTestFailureSubmit}
      />
    </div>
  );
};

export default SearchTestFailure;
