import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import conf from "../config/config";
import "./MyTestSuite.css"; // Import the new CSS file

const MyTestSuite = () => {
  const [testSuites, setTestSuites] = useState([]);
  const [page, setPage] = useState(1);
  const [totalTestSuites, setTotalTestSuites] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const { user } = useContext(AuthContext);
  const limit = 10; // Number of test suites per page

  useEffect(() => {
    const fetchTestSuites = async () => {
      try {
        const response = await axios.post(
          `${conf.API_BASE_URL}/dashboard/getMyTestSuite`,
          {
            userId: user.id,
            page,
            limit,
            sortKey: sortConfig.key,
            sortDirection: sortConfig.direction,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          }
        );

        console.log("API response:", response.data); // Debugging

        setTestSuites(response.data.testSuites || []);
        setTotalTestSuites(Number(response.data.totalTestSuites) || 0);
        console.log("Test suites:", response.data.testSuites); // Debugging
        console.log("Total test suites:", response.data.totalTestSuites); // Debugging
        console.log("Total pages:", totalPages); // Debugging
      } catch (error) {
        console.error("Error fetching test suites:", error);
        setTestSuites([]);
        setTotalTestSuites(0);
      }
    };

    fetchTestSuites();
  }, [page, limit, sortConfig, user]);

  const totalPages = Math.ceil(totalTestSuites / limit);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="test-suite-content">
      <h2>My Test Suites</h2>
      {testSuites.length === 0 ? (
        <p>No Test Suites Available</p>
      ) : (
        <>
          <table className="test-suite-table">
            <thead>
              <tr>
                <th onClick={() => requestSort("name")}>Name</th>
                <th onClick={() => requestSort("isactive")}>Is Active</th>
              </tr>
            </thead>
            <tbody>
              {testSuites.map((suite) => (
                <tr key={suite.objid}>
                  <td>
                    <Link to={`/testsuite/${suite.objid}`}>{suite.name}</Link>
                  </td>
                  <td>{suite.isactive === "true" ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="test-suite-pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => setPage(index + 1)}
                disabled={page === index + 1}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MyTestSuite;
