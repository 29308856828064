import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import conf from "../config/config";
import "./MyTasks.css"; // Import the new CSS file

const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalTasks, setTotalTasks] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const { user } = useContext(AuthContext);
  const limit = 10; // Number of tasks per page

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.post(
          `${conf.API_BASE_URL}/dashboard/getMyTasks`,
          {
            tasks2assignee: user.id,
            createdby: user.fullname,
            page,
            limit,
            sortKey: sortConfig.key,
            sortDirection: sortConfig.direction,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          }
        );
        setTasks(response.data.tasks);
        setTotalTasks(response.data.totalTasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, [page, limit, sortConfig, user]);

  const totalPages = Math.ceil(totalTasks / limit);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return (
      date.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        day: "2-digit",
        month: "short",
        year: "numeric",
        timeZone: "UTC", // Ensure the date is interpreted as UTC
      }) + " UTC"
    ); // Append " UTC" to the formatted date
  };

  return (
    <div className="dashboard-content">
      <h2>My Tasks</h2>
      {tasks.length === 0 ? (
        <p>No Tasks Available</p>
      ) : (
        <>
          <table className="task-table">
            <thead>
              <tr>
                <th onClick={() => requestSort("name")}>Name</th>
                <th onClick={() => requestSort("startdate")}>Start Date</th>
                <th onClick={() => requestSort("duedate")}>Due Date</th>
                <th onClick={() => requestSort("taskstatuscode")}>
                  Task Status
                </th>
                <th onClick={() => requestSort("assigneename")}>Assignee</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task) => (
                <tr key={task.objid}>
                  <td>
                    <Link to={`/tasks/${task.objid}`}>{task.name}</Link>
                  </td>
                  <td>{formatDate(task.startdate)}</td>
                  <td>{formatDate(task.duedate)}</td>
                  <td>{task.taskstatuscode}</td>
                  <td>{task.assigneename}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => setPage(index + 1)}
                disabled={page === index + 1}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TaskList;
